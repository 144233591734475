import { useState, useEffect } from "react";

import './Details.css';

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Textarea from "components/Textarea";
import Comp_InputFile from "components/InputFile";

import { GetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Reg_Students } from "services/Register";
import { cepMask, phoneMask } from "services/Mask";

export default function Page_Students_Details(props){

    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('students'));

    const [ name, setName ] = useState(InitialData('name'));
    const [ email, setEmail ] = useState(InitialData('email'));
    const [ file, setFile ] = useState(InitialData('file'));
    const [ fileStatus, setFileStatus ] = useState(false);
    const [ pass, setPass ] = useState('');
    const [ note, setNote ] = useState(InitialData('note'));
    const [ complement, setComplement ] = useState(InitialData('complement'));
    const [ phone, setPhone ] = useState(InitialData('phone'));
    const [ cep, setCep ] = useState(InitialData('cep'));
    const [ street, setStreet ] = useState(InitialData('street'));
    const [ state, setState ] = useState(InitialData('state'));
    const [ city, setCity ] = useState(InitialData('city'));
    const [ neighborhood, setNeighborhood ] = useState(InitialData('neighborhood'));
    const [ number, setNumber ] = useState(InitialData('number'));

    function InitialData(type){
        const newData = GetDataPage('students').find(item => item.id == idPage);   
        if(newData){
            return newData[type];            
        }
        return '';
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Students(props.idUser, idPage, name, email, file, pass, note, complement, phone, cep, street, state, city, neighborhood, number, props.CallbackSuccess, props.CallbackError);
    }

    useEffect(()=>{
        RegisterDataPage('students', setDataPage);

        return ()=>{
            UnRegisterDataPage('students', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));        

        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setFile(InitialData('file'));
        setFileStatus(false);
        setPass('');     
        setNote(InitialData('note'));
        setComplement(InitialData('complement'));
        setPhone(InitialData('phone'));
        setCep(InitialData('cep'));
        setStreet(InitialData('street'));
        setState(InitialData('state'));
        setCity(InitialData('city'));
        setNeighborhood(InitialData('neighborhood'));
        setNumber(InitialData('number'));
    }, [dataPage, idPage]);

    return(
        <form className="page_content page_students" onSubmit={ SaveData }>
            <Comp_Topic type={ true } icon="save" SearchInput="" search="" title="Informações do aluno" OpenPage={ props.OpenPage } page="students" idPage={ 0 } />
            
            <div className="show_page_data">
                <div className="type_title">Dados do aluno</div>
                <div className="list_input_data">
                    <Comp_Input className="" type="text" index="not" setValue={ setName } value={ name } input="" maxLength={ 140 } placeholder="" required={ true } name="Nome" />
                    
                    <Comp_Input className="" type="email" index="not" setValue={ setEmail } value={ email } input="" maxLength={ 40 } placeholder="" required={ true } name="E-mail" />
                    
                    <Comp_Input className="phone" type="text" index="not" setValue={ setPhone } value={ phoneMask(phone) } input="" maxLength={ 20 } placeholder="(xx) xxxxx-xxxx" required={ true } name="Telefone" />

                    <Comp_InputFile index="not" setValue={ setFile } value={ file } setStatus={ setFileStatus } status={ fileStatus } OpenFile={ props.OpenFile } thumbnail={ file } title1="Adicionar foto" title2="Foto adicionado" />
                    
                    <Comp_Input className="password" type="password" index="not" setValue={ setPass } value={ pass } input="" maxLength={ 10 } placeholder="*****" required={ idPage === 0 ? true : false } name="Senha" />
                    
                    <Comp_Textarea index="not" name="Observação" className="" setValue={ setNote } value={ note } />
                </div>
            </div>
            
            <div className="show_page_data">
                <div className="type_title">Endereço</div>
                <div className="list_input_data">
                    <Comp_Input index="not" type="text" name="CEP" className="cep" setValue={ setCep } value={ cepMask(cep) } maxLength={ 9 } placeholder="xxxxx-xx" />
                    
                    <Comp_Input index="not" type="text" name="Cidade" className="" setValue={ setCity } value={ city } />
                    
                    <Comp_Input index="not" type="text" name="UF" className="state" setValue={ setState } value={ state } placeholder=" " />

                    <Comp_Input index="not" type="text" name="Bairro" className="" setValue={ setNeighborhood } value={ neighborhood } />

                    <Comp_Input index="not" type="text" name="Rua" className="" setValue={ setStreet } value={ street } />

                    <Comp_Input index="not" type="text" name="Número" className="number" setValue={ setNumber } value={ number } placeholder="xxxx" />
                    
                    <Comp_Input index="not" type="text" name="Complemento" className="all" setValue={ setComplement } value={ complement } maxLength={ 140 } />
                </div>
            </div>
        </form>
    )
}