import React, { useState, useEffect } from "react";

import './Schedule.css';

import { GetDataPage } from "interface/Data";
import { GetUserData } from "interface/Users";
import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import { Svg_Close } from "services/SvgFile";
import { CheckedData } from "services/Register";

import Comp_Input from "components/Input";
import Comp_Select from "components/Select";
import Comp_Textarea from "components/Textarea";

export default function PopUP_Schedule(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ listData, setListData ] = useState([]);

    const [ id, setId ] = useState(0);
    const [ idGiven, setIdGiven ] = useState({ "value": 0, "label": "Selecione uma opção" });
    const [ title, setTitle ] = useState('');
    const [ status, setStatus ] = useState({ "value": 0, "label": "Pendente" });
    const [ subtitle, setSubtitle ] = useState('');
    const [ price, setPrice ] = useState('');
    const [ text, setText ] = useState('');
    const [ hour, setHour ] = useState(0);
    const [ start, setStart ] = useState('');

    function ListServices(){
        const newData = [];
        GetDataPage('services').map((elem, index)=>{
            if(newData.find(item => item.given == elem.id)){ }else {
                newData.push({ "value": elem.id, "label": elem.title, "price": elem.price, "text": elem.text });
            }
        })
        return newData
    }

    function SelectService(elem){
        setIdGiven({ "value": elem.value, "label": elem.label });
        setText(elem.text);
        setPrice(elem.price);
    }

    function SaveData(event){
        event.preventDefault(); 
        props.setLoading(true);
        if(id == 0){
            CheckedData('hour', start, hour, ReturnSuccess, ReturnError);
        }else {
            // Reg_Schedule(props.idUser, id, idGiven['value'], title, status['value'], subtitle, price, text, hour, start, ()=>{ props.CallbackSuccess(); ClosePopUp(); }, ()=>{ props.CallbackError() }); 
        }
    }

    function ReturnSuccess(){
        // Reg_Schedule(props.idUser, id, idGiven['value'], title, status['value'], subtitle, price, text, hour, start, ()=>{ props.CallbackSuccess(); ClosePopUp(); }, ()=>{ props.CallbackError() }); 
    }

    function ReturnError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "O horário selecionado já consta reservada, favor selecionar um novo horario!" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        setId(0);
        setIdGiven({ "value": 0, "label": "Selecione uma opção" });
        setTitle('');
        setStatus({ "value": 0, "label": "Pendente" });
        setSubtitle('');
        setPrice('');
        setText('');
        setHour('');
        setStart('');

        SetModalState('Schedule', false);
    }

    useEffect(()=>{
        RegisterModalData('Schedule', setModaldata);
        RegisterModalObserver('Schedule', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp){
            setListData(ListServices())

            setId(modalData.id);
            setIdGiven(modalData.id_given);
            setTitle(modalData.title);
            setStatus(modalData.status);
            setSubtitle(modalData.subtitle);
            setPrice(modalData.price);
            setText(modalData.text);
            setHour(modalData.hour);
            setStart(modalData.start);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <form className="PopUp" onSubmit={ SaveData }>
                <div className="all Schedule">
                    <div className="div_data type_div">
                        <div className="title">
                            Agenda
                        </div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <Svg_Close className="icons" color="#f00000" />
                        </div>
                    </div>

                    <div className="div_data div_content" style={ { paddingTop: 0 } }>
                        <div className="content">
                            <Comp_Input index="not" type="text" className="" name="Título*" setValue={ setTitle } value={ title } maxLength={ 140 } required={ true } />
                            
                            <Comp_Select index="not" name="Status" className="status" opt={ [ { "value": 0, "label": "Pendente" }, { "value": 1, "label": "Finalizado" }, { "value": 2, "label": "Cancelado" } ] } setValue={ setStatus } value={ status } />
                        </div>
                        <div className="content">                            
                            <Comp_Select index="not" name="Lista de serviços" className="" opt={ listData } setValue={ SelectService } value={ idGiven } />
                        </div>
                        <div className="content">
                            <Comp_Input index="not" type="text" className="" name="Subtitulo" setValue={ setSubtitle } value={ subtitle } maxLength={ 140 } />
                            
                            <Comp_Input index="not" type="date" className="date" name="Data*" setValue={ setStart } value={ start } maxLength={ 140 } required={ true } />

                            <Comp_Input index="not" type="time" className="hour" name="Hora*" setValue={ setHour } value={ hour } maxLength={ 140 } required={ true } />

                            <Comp_Input index="not" type="text" className="price" name="Valor" setValue={ setPrice } value={ price } maxLength={ 10 } placeholder="0,00" />
                        </div>

                        <div className="content">
                            <Comp_Textarea index="not" name="Descrição" setValue={ setText } value={ text } />
                        </div>

                        <div className="div_btn">
                            <button type="submit" className="btn_save">Salvar</button>
                        </div>
                    </div>
                </div>
            </form>
        : <React.Fragment></React.Fragment>)
    );
}
