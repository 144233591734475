import { useState, useEffect } from "react";

import './Details.css';

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Select from "components/Select";
import Comp_Textarea from "components/Textarea";
import Comp_InputFile from "components/InputFile";

import { GetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Reg_Courses } from "services/Register";
import { Svg_Delete, Svg_Edit } from "services/SvgFile";

import { defaultColor } from "fixedData";

export default function Page_Courses_Details(props){

    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('courses'));

    const [ title, setTitle ] = useState(InitialData('title'));
    const [ price, setPrice ] = useState(InitialData('price'));
    const [ discount, setDiscount ] = useState(InitialData('discount'));
    const [ typeVideo, setTypeVideo ] = useState(InitialData('type_video'));
    const [ video, setVideo ] = useState(InitialData('video'));
    const [ text, setText ] = useState(InitialData('text'));
    const [ classes, setClasses ] = useState(InitialData('classes'));
    
    const [ cover, setCover ] = useState(InitialData('cover'));
    const [ coverStatus, setCoverStatus ] = useState(false);
    const [ cropX, setCropX ] = useState('');
    const [ cropY, setCropY ] = useState('');
    const [ width, setWidth ] = useState('');
    const [ height, setHeight ] = useState('');
    const [ clientWidth, setClientWidth ] = useState('');
    const [ naturalWidth, setNaturalWidth ] = useState('');

    function InitialData(type){
        const newData = GetDataPage('courses').find(item => item.id == idPage);   
        if(newData){
            return newData[type];            
        }
        if(type === 'type_video'){
            return { "value": "Vimeo", "label": "Vimeo" };
        }
        if(type === 'classes'){
            return [];
        }
        return '';
    }

    /* crop */
    function SaveCrop(value){        
        setCover(value.file);
        setCoverStatus(true);
        setCropX(value.cropX);
        setCropY(value.cropY);
        setWidth(value.width);
        setHeight(value.height);
        setClientWidth(value.clientWidth);
        setNaturalWidth(value.naturalWidth);        
    }
    function PopUpCrop(type, file, id){
        SetModalData('PhotoCrop', { "idPage": idPage, "type": type, "file": file, "id": id, "showImg": URL.createObjectURL(file), "SaveCrop": SaveCrop });
        SetModalState('PhotoCrop', true);
    }
    /* end */

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Courses(props.idUser, idPage, title, price, discount, cover, typeVideo['value'], video, text, cropX, cropY, width, height, clientWidth, naturalWidth, props.CallbackSuccess, props.CallbackError);
    }

    useEffect(()=>{
        RegisterDataPage('courses', setDataPage);

        return ()=>{
            UnRegisterDataPage('courses', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));

        setTitle(InitialData('title'));
        setPrice(InitialData('price'));
        setDiscount(InitialData('discount'));
        setTypeVideo(InitialData('type_video'));
        setVideo(InitialData('video'));
        setText(InitialData('text'));
        setClasses(InitialData('classes'));
        
        setCover(InitialData('cover'));
        setCoverStatus(false);
        setCropX('');
        setCropY('');
        setWidth('');
        setHeight('');
        setClientWidth('');
        setNaturalWidth('');
    }, [dataPage, idPage]);

    return(
        <form className="page_content page_courses" onSubmit={ SaveData }>
            <Comp_Topic type={ true } icon="save" SearchInput="" search="" title="Informações do curso" OpenPage={ props.OpenPage } page="courses" idPage={ 0 } />
            
            <div className="show_page_data">
                <div className="type_title">Dados do curso</div>
                <div className="list_input_data">
                    <Comp_Input className="" type="text" index="not" setValue={ setTitle } value={ title } input="" maxLength={ 140 } placeholder="" required={ true } name="Título*" />
                    
                    <Comp_Input className="price" type="text" index="not" setValue={ setPrice } value={ price } input="" maxLength={ 10 } placeholder="0,00" required={ true } name="Valor R$*" />
                    
                    <Comp_Input className="price" type="text" index="not" setValue={ setDiscount } value={ discount } input="" maxLength={ 10 } placeholder="0,00" required={ false } name="Disconto" />

                    <Comp_InputFile index="not" setValue={ setCover } value={ cover } setStatus={ setCoverStatus } status={ coverStatus } OpenFile={ props.OpenFile } thumbnail={ cover } title1="Adicionar capa" title2="Capa adicionada" crop={ PopUpCrop } typeCrop="file" idCrop="0" />
                </div>

                <div className="list_input_data">
                    <Comp_Select index="not" name="Tipo de vídeo*" className="type_video" opt={ [{ "value": "Vimeo", "label": "Vimeo" }, { "value": "Youtube", "label": "Youtube" }] } setValue={ setTypeVideo } value={ typeVideo } input="" />

                    <Comp_Input className="" type="text" index="not" setValue={ setVideo } value={ video } input="" maxLength={ 140 } placeholder=" " required={ true } name="Link do vídeo*" showVideo={ typeVideo['value'] } OpenFile={ props.OpenFile } />
                    
                    <Comp_Textarea index="not" name="Descrição" className="" setValue={ setText } value={ text } />
                </div>
            </div>
            
            {
                idPage !=0 ?
                    <div className="show_page_data">
                        <div className="type_title">
                            <div className="">Aulas</div>
                            <div className="" onClick={ ()=>{ props.OpenPage('currentPage', "courses_classe_details"); props.OpenPage('currentPageIdClasse', 0); } }>Adicionar modulo/aula</div>
                        </div>
                        <div className="list_input_data">                    
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th width="120" style={ { backgroundColor: defaultColor } }>Módulo</th>
                                        <th width="20" align="center" style={ { backgroundColor: defaultColor } }>#</th>
                                        <th style={ { backgroundColor: defaultColor } }>Título</th>
                                        <th width="60" align="right" style={ { backgroundColor: defaultColor } }>#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        classes.length > 0 ?
                                            classes.map((elem, index)=>{
                                                return(
                                                    <tr key={ index }>
                                                        <td colSpan={ 4 } style={ { paddingRight: 0 } }>
                                                            <div className="">{ elem.name }</div>
                                                            <table className="space_table" width="100%" cellPadding="0" cellSpacing="0">
                                                                <tbody>
                                                                    {
                                                                        elem.list.map((elem_1, index_1)=>{
                                                                            return(
                                                                                <tr key={ index_1 }>
                                                                                    <td width="20" align="center">{ index_1 + 1 }</td>
                                                                                    <td>{ elem_1.title }</td>
                                                                                    <td width="60" align="right">
                                                                                        <div className="div_opt_alt">
                                                                                            <div data-tooltip-id="show_alert" data-tooltip-content="Deletar aula" data-tooltip-place="top" onClick={ ()=>{ props.AltAccess("courses", "delete_classe", "Deletar aula", elem_1.id, elem_1.name, 0); } } title="Deletar aula">
                                                                                                <Svg_Delete color="#F00000" className="icons"/>
                                                                                            </div>

                                                                                            <div data-tooltip-id="show_alert" data-tooltip-content="Editar aula" data-tooltip-place="top" onClick={ ()=>{ props.OpenPage('currentPage', "courses_classe_details"); props.OpenPage('currentPageIdModule', elem.id); props.OpenPage('currentPageIdClasse', elem_1.id); } } title="Editar aula">
                                                                                                <Svg_Edit color="#324d6b" className="icons"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        :
                                        <tr>
                                            <td colSpan={ 4 } className="no_data">Nenhum dado encontrado...</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                : null
            }
        </form>
    )
}