
let DataPage = {
    "upload_site" : false,
    "settings" : {},
    "access_dash" : [],

    "courses" : [],
    "category" : [],
    "students" : [],

    "entry" : [],
    "exit" : [],

    "site" : [],
    "faq" : [],
    "name_menu" : [],
    "schedule" : []
};

let NotifyDataPage = {
    "upload_site" : [],
    "settings" : [],
    "access_dash" : [],

    "courses" : [],
    "category" : [],
    "students" : [],

    "entry" : [],
    "exit" : [],

    "site" : [],
    "faq" : [],
    "name_menu" : [],
    "schedule" : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["upload_site"].forEach(element => { element(value['upload_site']); });
    NotifyDataPage["settings"].forEach(element => { element(value['settings']); });
    NotifyDataPage["access_dash"].forEach(element => { element(value['access_dash']); });
    NotifyDataPage["courses"].forEach(element => { element(value['courses']); });
    NotifyDataPage["category"].forEach(element => { element(value['category']); });
    NotifyDataPage["students"].forEach(element => { element(value['students']); });
    NotifyDataPage["entry"].forEach(element => { element(value['entry']); });
    NotifyDataPage["exit"].forEach(element => { element(value['exit']); });
    NotifyDataPage["site"].forEach(element => { element(value['site']); });
    NotifyDataPage["faq"].forEach(element => { element(value['faq']); });
    NotifyDataPage["name_menu"].forEach(element => { element(value['name_menu']); });
    NotifyDataPage["schedule"].forEach(element => { element(value['schedule']); });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}

export function UnRegisterDataPage(key, callback){
    if(NotifyDataPage[key].length > 0){
        NotifyDataPage[key] = NotifyDataPage[key].filter((item) => {
            return item !== callback;
        });
    }
}