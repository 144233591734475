import { useState, useEffect } from "react";

import './Site.css';

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import Comp_Topic from "components/Topic";

import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { defaultColor } from "fixedData";

import { Svg_Edit } from "services/SvgFile";

export default function Page_Site(props){

    const [ search, setSearch ] = useState('');
    const [ showPageData, setShowPageData ] = useState(GetDataPage('site'));

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('site').forEach(item =>{
                if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.subtitle.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.text.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowPageData(duplicate);
        }else {
            setShowPageData(GetDataPage('site'));
        }
        setSearch(value);
    }

    useEffect(()=>{
        RegisterDataPage('site', setShowPageData);

        return ()=>{
            UnRegisterDataPage('site', setShowPageData);
        };
    }, []);

    return(
        <div className="page_content page_home">
            <Comp_Topic type={ false } icon="add" SearchInput={ SearchInput } search={ search } title="Layout Site" OpenPage={ props.OpenPage } page="site_details" idPage={ 0 } />

            <div className="list_data_page">
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th width="20" align="center" style={ { backgroundColor: defaultColor } }>#</th>
                            <th width="130" className="td_1" style={ { backgroundColor: defaultColor } }>Menu</th>
                            <th style={ { backgroundColor: defaultColor } }>Título</th>
                            <th width="20" align="right" style={ { backgroundColor: defaultColor } }>#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            showPageData.length > 0 ?
                            showPageData.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ index + 1 }</td>
                                        <td className="td_1">{ elem.origin }</td>
                                        <td>{ elem.title }</td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                <div data-tooltip-id="show_alert" data-tooltip-content="Editar bloco" data-tooltip-place="top" onClick={ ()=>{ props.OpenPage('currentPage', 'site_details'); props.OpenPage('currentPageId', elem.id) } } title="Editar bloco">
                                                    <Svg_Edit color="#324d6b" className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={ 4 } align="center">Nenhum dado encontrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>
                <Tooltip id="show_alert" />
            </div>
        </div>
    );
}