import './InputFile.css';

import { useEffect, useState } from 'react';

import { colorIcon } from 'fixedData';

import { Svg_Delete, Svg_Pdf, Svg_Youtube } from 'services/SvgFile';

export default function Comp_InputFile(props){

    const [ file, setFile ] = useState(props.value);
    const [ status, setStatus ] = useState(props.status);

    function RegisterData(value){
        if(value){
            if(props.index === "not"){
                if(props.crop){
                    props.crop(props.typeCrop, value, 0);
                }else {
                    props.setValue(value);
                    props.setStatus(true);
                }
            }else {
                if(props.crop){
                    props.crop(props.typeCrop, value, props.idCrop);
                }else {
                    props.setValue('status', props.index, true);
                    props.setValue('file', props.index, value);
                }
            }
        }else {
            if(props.index === "not"){
                props.setValue('');
                props.setStatus(false);
            }else {
                props.setValue('status', props.index, false);
                props.setValue('file', props.index, '');
            }
        }
    }

    function ShowFile(file){        
        let showFile = file.split('/');
        let infFile = '';
        if(showFile[6]){
            infFile = showFile[6].split('.')[1];
        }

        return(
            props.status ?
                infFile === 'pdf' ?
                    <div className="show_img" onClick={ ()=>{ props.OpenFile('pdf', URL.createObjectURL(showFile[6])) } }>
                        <Svg_Pdf className="icons" color={ colorIcon } />
                    </div>
                :
                <div className="show_img" onClick={ ()=>{ props.OpenFile('img', URL.createObjectURL(showFile[6])) } }>
                    <img alt="img_" src={ URL.createObjectURL(props.value) } className="img_thumbnail" />
                </div>
            :
                infFile === 'pdf' ?
                    <div className="show_img" onClick={ ()=>{ props.OpenFile('pdf', props.value) } }>
                        <Svg_Pdf className="icons" color={ colorIcon } />
                    </div>
                :
                <div className="show_img" onClick={ ()=>{ props.OpenFile('img', props.value) } }>
                    <img alt="img_" src={ props.thumbnail } className="img_thumbnail" />
                </div>
        )
    }

    useEffect(()=>{
        setFile(props.value);
        setStatus(props.status);
    }, [props.value, props.status]);

    return(
        <div className="Comp_InputFile">
            <label className={ props.status ? "label_active" : props.value ? "show_file_" : "" }>
                <input type="file" onChange={ (e)=>{ RegisterData(e.target.files[0]) } } accept={ props.accept ? props.accept : "" } />

                <div className="title">
                    {
                        props.status ? props.title2 : props.title1
                    }
                </div>
            </label>
            {
                props.value ? 
                <>                    
                    <div className="div_remuve" onClick={ ()=>{ RegisterData(''); } }>
                        <Svg_Delete className="icons" color="#ffffff" />
                    </div>
                    {
                        props.type === "video" ?
                        <div className="show_img" onClick={ ()=>{ props.OpenFile('video_local', props.status ? URL.createObjectURL(file) : props.value) } }>
                            <Svg_Youtube className="icons" color={ colorIcon } />
                        </div>
                        :
                        ShowFile(props.value)
                    }
                </>
                : null
            }
        </div>
    )
}