import { useState, useEffect } from "react";

import './Topic.css';

import { Svg_AddNewData, Svg_ArrowRight, Svg_Save, Svg_Search } from "services/SvgFile";

import Comp_ReturnPage from "components/ReturnPage";
import Comp_Search from "components/Search";

export default function Comp_Topic(props){

    return(
        <div className="Comp_Topic">
            <div className="new_block">
                {
                    props.SearchInput ? 
                        props.SearchInput === "no_return" ? null :
                        <Comp_Search SearchInput={ props.SearchInput } search={ props.search } />
                        :
                        <Comp_ReturnPage OpenPage={ props.OpenPage } page={ props.page } idPage={ props.idPage } typePage={ props.typePage } />
                }
                <div className="title_page">
                    { props.title }
                </div>
                {
                    props.type ? 
                        props.icon === "add" ? 
                            <div className="list_opt_alt_page">
                                {
                                    props.OpenPopUp ? 
                                    <div type="submit" className="div_add_new_data" onClick={ ()=>{ props.OpenPopUp(props.idPage, "", "") } }>
                                        <div className="new_data_add">
                                            <Svg_AddNewData className="icons" color="#ffffff" />
                                        </div>
                                        <div className="name_btn_add">Adicionar</div>
                                    </div>
                                    :
                                    <div type="submit" className="div_add_new_data" onClick={ ()=>{ props.OpenPage('currentPage', props.page); props.OpenPage('currentPageId', props.idPage) } }>
                                        <div className="new_data_add">
                                            <Svg_AddNewData className="icons" color="#ffffff" />
                                        </div>
                                        <div className="name_btn_add">Adicionar</div>
                                    </div>
                                }
                            </div>
                        :
                            <div className="list_opt_alt_page">
                                <button type="submit" className="div_add_new_data">
                                    <div className="new_data_add">
                                        <Svg_Save className="icons" color="#ffffff" />
                                    </div>
                                    <div className="name_btn_add">Salvar</div>
                                </button>
                            </div>
                    : null
                }
            </div>
        </div>
    )
}