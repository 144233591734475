import React, { useState, useEffect } from "react";

import './Faq.css';

import { SetModalState, RegisterModalData, RegisterModalObserver } from "interface/PopUp";

import { Reg_Faq } from "services/Register";
import { Svg_Close } from "services/SvgFile";

import Comp_Input from "components/Input";
import Comp_Textarea from "components/Textarea";

export default function PopUP_Faq(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ id, setId ] = useState(0);
    const [ title, setTitle ] = useState('');
    const [ text, setText ] = useState('');
    
    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Faq(props.idUser, id, title, text, ()=>{ props.CallbackSuccess(); ClosePopUp(); }, ()=>{ props.CallbackError() });        
    }

    function ClosePopUp(){
        setId(0);
        setTitle('');
        setText('');
        SetModalState('Faq', false);
    }

    useEffect(()=>{
        RegisterModalData('Faq', setModaldata);
        RegisterModalObserver('Faq', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp){
            setId(modalData.id);
            setTitle(modalData.title);
            setText(modalData.text);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <form onSubmit={ SaveData }>
                <div className="PopUp PopUpCenter">
                    <div className="all Faq">
                        <div className="div_data type_div">
                            <div className="title">
                                Faq
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <Svg_Close color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data">
                            <div className="content">
                                <div className="list_input_data">
                                    <Comp_Input index="not" type="text" name="Pergunta" className="" setValue={ setTitle } value={ title } />
                                </div> 
                                <div className="list_input_data">
                                    <Comp_Textarea index="not" name="Resposta" className="" setValue={ setText } value={ text } /> 
                                </div> 
                                <div className="div_btn">
                                    <button type="submit" className="btn_save">Salvar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        : <React.Fragment></React.Fragment>)
    );
}
