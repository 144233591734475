import './ReturnPage.css';

import { colorIcon } from 'fixedData';

import { Svg_ArrowRight } from 'services/SvgFile';

export default function Comp_ReturnPage(props){    
    return(        
        <div className="div_return" onClick={ ()=>{ props.OpenPage('currentPage', props.page); props.OpenPage(props.typePage ? 'currentPageIdClasse' : 'currentPageId', 'remuve') } }>
            <Svg_ArrowRight className="icons" color={ colorIcon } />
            <div className="">Voltar</div>
        </div>
    )
}