import React, { useState, useEffect, useRef } from 'react';

import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';

import './PhotoCrop.css';

import { RegisterModalData, RegisterModalObserver, SetModalState } from 'interface/PopUp';

import { Svg_Close } from 'services/SvgFile';
import { Reg_Crop } from 'services/Register';

export default function PopUp_PhotoCrop(props){

    const positionImg = useRef();
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ typeCrop, setTypeCrop ] = useState(0);
    const [ proportionX, setProportionX ] = useState(0);
    const [ proportionY, setProportionY ] = useState(0);

    const [ crop, setCrop ] = useState({
        unit: '%', // Can be 'px' or '%'
        aspect: proportionX / proportionY,
        x: 0,
        y: 0,
        width: 0,
        height: 0
    });

    async function SaveCropPhoto (){
        if(modalData.SaveCrop){
            modalData.SaveCrop(
                {
                    "file": modalData.file,
                    "cropX": crop.x,
                    "cropY": crop.y,
                    "width": crop.width,
                    "height": crop.height,
                    "clientWidth": positionImg.current.clientWidth,
                    "naturalWidth": positionImg.current.naturalWidth
                }
            );
        }else {
            props.setLoading(true);
            Reg_Crop(props.idUser, modalData.idPage, modalData.id, modalData.type, modalData.file, typeCrop, crop.x, crop.y, crop.width, crop.height, positionImg.current.clientWidth, positionImg.current.naturalWidth, props.CallbackSuccess, props.CallbackError);
        }
        SetModalState('PhotoCrop', false);
    };
 
    function onImageLoad(e) {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
        let crop = "";
        crop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                }
            ),
            width,
            height
        )  
        setCrop(crop);
    }

    function ClosePopUp(){
        setTypeCrop(0);
        setProportionX(0);
        setProportionY(0);
        SetModalState('PhotoCrop', false);
    }

    useEffect(()=>{
        RegisterModalData('PhotoCrop', setModaldata);
        RegisterModalObserver('PhotoCrop', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            if(modalData.SaveCrop){
                setTypeCrop(1);
                setProportionX(16); 
                setProportionY(16);
            }
        }
    }, [showPopUp]);

    return (
        (showPopUp ? 
            <div className="PopUp">
                <div className="all PhotoCrop">
                    <div className="div_data type_div">
                        <div className="title">Recorte de foto</div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <Svg_Close color="#F00000" className="icons" />
                        </div>
                    </div>
                    <div className="div_data" style={ { paddingTop: 0 } }>
                        <div className="content">
                            {
                                modalData.SaveCrop ? null :
                                <div className="div_type_crop">
                                    <div className={ typeCrop === 0 ? "type_crop crop_open" : "type_crop" } onClick={ ()=>{ setTypeCrop(0); } }>Sem recorte</div>
                                    <div className={ typeCrop === 1 ? "type_crop crop_open" : "type_crop" } onClick={ ()=>{ setTypeCrop(1); setProportionX(16); setProportionY(16); setCrop({}) } }>Recorte 16/16</div>
                                    <div className={ typeCrop === 2 ? "type_crop crop_open" : "type_crop" } onClick={ ()=>{ setTypeCrop(2); setProportionX(9); setProportionY(16); setCrop({}) } }>Recorte 9/16</div>
                                    <div className={ typeCrop === 3 ? "type_crop crop_open" : "type_crop" } onClick={ ()=>{ setTypeCrop(3); setProportionX(16); setProportionY(9); setCrop({}) } }>Recorte 16/9</div>
                                </div>
                            }

                            {
                                typeCrop === 0 ?
                                <>
                                    <div className="btn_save" onClick={ ()=>{ SaveCropPhoto() } }>Salvar foto</div>
                                    <div className="image_crop">
                                        <img src={ modalData.showImg } ref={ positionImg } className="image" />
                                    </div>
                                </>
                                :
                                <>
                                    { modalData.SaveCrop ? <div className="space_top" /> : null }
                                    {
                                        crop.width > 0 ? 
                                        <div className="btn_save" onClick={ ()=>{ SaveCropPhoto() } }>Salvar foto</div> :
                                        <div className="">É necessário recortar a imagem para salvar</div>
                                    }

                                    <div className="image_crop">
                                        <ReactCrop crop={ crop } onChange={ setCrop } aspect={ proportionX / proportionY }>
                                            <img ref={ positionImg } src={ modalData.showImg } onLoad={ onImageLoad } className="image" />
                                        </ReactCrop>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div> 
        : <React.Fragment></React.Fragment>)
    );
}