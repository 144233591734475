import { useState, useEffect } from "react";

import './Courses.css';

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import Comp_Topic from "components/Topic";

import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Svg_Delete, Svg_Edit } from "services/SvgFile";

import { defaultColor } from "fixedData";

export default function Page_Courses(props){

    const [ search, setSearch ] = useState('');
    const [ showPageData, setShowPageData ] = useState(GetDataPage('courses'));
    const [ listData, setListData ] = useState(GetDataPage('courses'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(listData.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = listData.slice(startItens, endItens);

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('courses').forEach(item =>{
                if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.text.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.obs.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListData(duplicate);
        }else {
            setListData(GetDataPage('courses'));
        }
        setSearch(value);
    }

    useEffect(()=>{
        RegisterDataPage('courses', setShowPageData);

        return ()=>{
            UnRegisterDataPage('courses', setShowPageData);
        };
    }, []);

    useEffect(()=>{
        setListData(GetDataPage('courses'));
    }, [showPageData]);

    return(
        <div className="page_content page_courses">
            <Comp_Topic type={ true } icon="add" SearchInput={ SearchInput } search={ search } title="Cursos" OpenPage={ props.OpenPage } page="courses_details" idPage={ 0 } />

            <div className="list_data_page">
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th width="20" align="center" style={ { backgroundColor: defaultColor } }>#</th>
                            <th style={ { backgroundColor: defaultColor } }>Título</th>
                            <th className="td_1" style={ { backgroundColor: defaultColor } }>Descrição</th>
                            <th width="60" align="right" style={ { backgroundColor: defaultColor } }>#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.title }</td>
                                        <td className="td_1">{ elem.text }</td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                <div data-tooltip-id="show_alert" data-tooltip-content="Deletar curso" data-tooltip-place="top" onClick={ ()=>{ props.AltAccess("courses", "delete_data", "Deletar curso", elem.id, elem.name, 0); } } title="Deletar curso">
                                                    <Svg_Delete color="#F00000" className="icons"/>
                                                </div>

                                                <div data-tooltip-id="show_alert" data-tooltip-content="Editar curso" data-tooltip-place="top" onClick={ ()=>{ props.OpenPage('currentPage', "courses_details"); props.OpenPage('currentPageId', elem.id); } } title="Editar curso">
                                                    <Svg_Edit color="#324d6b" className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={ 6 } align="center">Nenhum dado encontrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>
                <Tooltip id="show_alert" />

                {
                    listData.length >= 25 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage === index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}