import { useState, useEffect } from "react";

import './Details.css';

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Select from "components/Select";
import Comp_Textarea from "components/Textarea";
import Comp_InputFile from "components/InputFile";

import { GetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Reg_Exit } from "services/Register";

export default function Page_Exit_Details(props){

    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('exit'));

    const [ title, setTitle ] = useState(InitialData('title'));
    const [ status, setStatus ] = useState(InitialData('status'));
    const [ dueDate, setDueDate ] = useState(InitialData('due_date'));
    const [ paymentDate, setPaymentDate ] = useState(InitialData('payment_date'));
    const [ price, setPrice ] = useState(InitialData('price'));
    const [ type, setType ] = useState(InitialData('type'));
    const [ idGiven, setIdGiven ] = useState(InitialData('id_given'));
    const [ name, setName ] = useState(InitialData('name'));
    const [ paymentType, setPaymentType ] = useState(InitialData('payment_type'));
    const [ newPaymentType, setNewPaymentType ] = useState('');
    const [ voucher, setVoucher ] = useState(InitialData('voucher'));
    const [ voucherStatus, setVoucherStatus ] = useState(false);
    const [ text, setText ] = useState(InitialData('text'));

    function InitialData(type){
        const newData = GetDataPage('exit').find(item => item.id == idPage);   
        if(newData){
            return newData[type];            
        }
        if(type === 'status'){
            return { "value": 0, "label": "Pendente" };
        }
        if(type === 'type'){
            return { "value": 1, "label": "Outros" };
        }
        if(type === 'id_given'){
            return { "value": 0, "label": "Selecione uma opção" };
        }
        if(type === 'payment_type'){
            return { "value": "add_new_data", "label": "Adicionar nova opção" };
        }
        return '';
    }

    function ListStudents(){
        const newData = [];
        GetDataPage('access_site').map((elem, index)=>{
            if(newData.find(item => item.value == elem.id)){ }else {
                newData.push({ "value": elem.id, "label": elem.name });
            }
        });
        return newData;
    }

    function ListPaymentType(){
        const newData = [{ "value": "add_new_data", "label": "Adicionar nova opção" }];
        GetDataPage('exit').map((elem, index)=>{
            if(newData.find(item => item.value == elem.payment_type['value'])){ }else {
                newData.push({ "value": elem.payment_type['value'], "label": elem.payment_type['value'] });
            }
        });
        return newData;
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Exit(props.idUser, idPage, title, status, dueDate, paymentDate, price, type, idGiven, name, paymentType, newPaymentType, voucher, text, props.CallbackSuccess, props.CallbackError);
    }

    useEffect(()=>{
        RegisterDataPage('exit', setDataPage);

        return ()=>{
            UnRegisterDataPage('exit', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));

        setTitle(InitialData('title'));
        setStatus(InitialData('status'));
        setDueDate(InitialData('due_date'));
        setPaymentDate(InitialData('payment_date'));
        setPrice(InitialData('price'));
        setType(InitialData('type'));
        setIdGiven(InitialData('id_given'));
        setName(InitialData('name'));
        setPaymentType(InitialData('payment_type'));
        setNewPaymentType('');
        setVoucher(InitialData('voucher'));
        setVoucherStatus(false);
        setText(InitialData('text'));
    }, [dataPage, idPage]);

    return(
        <form className="page_content page_finance_exit" onSubmit={ SaveData }>
            <Comp_Topic type={ true } icon="save" SearchInput="" search="" title="Financeiro - Saída" OpenPage={ props.OpenPage } page="finance_exit" idPage={ 0 } />
            
            <div className="show_page_data">
                <div className="type_title">Dados do pagamento</div>
                <div className="list_input_data">
                    <Comp_Input className="" type="text" index="not" setValue={ setTitle } value={ title } input="" maxLength={ 140 } placeholder="" required={ true } name="Título" />

                    <Comp_Select index="not" name="Status" className="access" opt={ [{ "value": 0, "label": "Pendente" }, { "value": 1, "label": "Finalizado" }, { "value": 2, "label": "Cancelado" }] } setValue={ setStatus } value={ status } input="" />
                </div>

                <div className="list_input_data">                    
                    <Comp_Input className="date_input" type="date" index="not" setValue={ setDueDate } value={ dueDate } placeholder="" required={ false } name="Data do vencimento" />
                    
                    <Comp_Input className="date_input" type="date" index="not" setValue={ setPaymentDate } value={ paymentDate } placeholder="" required={ false } name="Data do pagamento" />
                    
                    <Comp_Input className="price" type="text" index="not" setValue={ setPrice } value={ price } placeholder="0,00" required={ false } name="Valor" />

                    <Comp_Select index="not" name="Origem" className="access" opt={ [{ "value": 0, "label": "Alunos" }, { "value": 1, "label": "Outros" }] } setValue={ setType } value={ type } input="" />

                    {
                        type['value'] == 0 ?
                        <Comp_Select index="not" name="Lista dos alunos" className="" opt={ ListStudents() } setValue={ setIdGiven } value={ idGiven } input="" /> 
                        :                    
                        <Comp_Input className="" type="text" index="not" setValue={ setName } value={ name } required={ false } name="Nome" />
                    }    
                    
                    <Comp_Select index="not" name="Tipo de pagamento" className="" opt={ ListPaymentType() } setValue={ setPaymentType } value={ paymentType } input="" />

                    {
                        paymentType['value'] == "add_new_data" ?
                        <Comp_Input className="new_payment" type="text" index="not" setValue={ setNewPaymentType } value={ newPaymentType } required={ false } name="Nome do novo tipo de pagamento" />
                        : null
                    }

                    <Comp_InputFile index="not" setValue={ setVoucher } value={ voucher } setStatus={ setVoucherStatus } status={ voucherStatus } OpenFile={ props.OpenFile } thumbnail={ voucher } title1="Adicionar comprovante" title2="Comprovante adicionado" />
                    
                    <Comp_Textarea index="not" name="Observação" className="" setValue={ setText } value={ text } />
                </div>
            </div>
        </form>
    )
}