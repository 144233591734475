import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListPag } from 'interface/Page';
import { SetListDataSingle } from 'interface/Data';
import { GetUserData, SetUserData } from 'interface/Users';

import { cookiesRegister, typeCookieEmail, typeCookiePassw, urlPhp } from 'fixedData';

// Delete or alt status data
export function Reg_AltStatus(userId, origin, type, id, status, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('id_user', userId);
    data.append('type_page', origin);
    data.append('edit_data', type);
    data.append('id', id);
    data.append('status', status);

    Axios({
        url : urlPhp + 'page/' + origin + '.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle(origin, response.data[origin]);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Checked Data
export function CheckedData(edit_data, start, hour, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'checked_data');
    data.append('edit_data', edit_data);
    data.append('start', start);
    data.append('hour', hour);
    data.append('id_client', GetUserData('id_client'));

    Axios({
        url : urlPhp + 'inc/checked_data.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        switch (response.data) {
            case "denied":
                    CallbackError();                
                break;
            case "released":
                    CallbackSuccess();                
                break;
        }
        

    }).catch((error)=>{
        CallbackError();
    })
}


// Recover pass
export function CheckedEmail(email, CallbackSuccess, CallbackError, CallbackError_Send, CallbackError_NotEmail){
    const data = new FormData();

    data.append('type_page', "recover_email");
    data.append('email', email);

    Axios({
        url : urlPhp + 'email/recover_pass.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);        
        switch (response.data) {
            case "Email sent successfully": case "Connected": CallbackSuccess(); break;
            case "Error sending message": case "Error": CallbackError_Send(); break;
            case "Email not sent": case "Error": CallbackError_NotEmail(); break;
            case "Email not found": case "Error": CallbackError(); break;
            default: CallbackError(); break;
        }

    }).catch((error)=>{
        console.log(error);        
        CallbackError();
    })
}
// Checked pass or alt pass
export function CodeNewPass(type, pass, code, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', type);
    data.append('pass', pass);
    data.append('code', code);

    Axios({
        url : urlPhp + 'email/recover_pass.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);        
        switch (response.data) {
            case "Email successfully sent": case "Connected":
                    CallbackSuccess(); 
                break;
            default: 
                    CallbackError(); 
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}


// Site
export function Reg_Site(userId, id, title, subtitle, text, type, file, bgFile, btn, slideshow, CallbackSuccess, CallbackError){
    const data = new FormData();    
    data.append('type_page', 'site');
    data.append('edit_data', 'edit_or_register');
    data.append('id_user', userId);
    data.append('id', id);
    data.append('title', title);
    data.append('subtitle', subtitle);
    data.append('text', text);
    data.append('type', type['value']);
    data.append('file', file);
    data.append('bg_img', bgFile);

    /* btn */
    btn.forEach((elem, index) => {
        data.append('btn_id[]', elem.id);
        data.append('btn_title[]', elem.title);
        data.append('btn_text[]', elem.text);
        data.append('btn_file_' + index, elem.file);
    });
    /* end */

    /* slideshow */
    slideshow.forEach((elem, index) => {
        data.append('slideshow_id[]', elem.id);
        data.append('slideshow_title[]', elem.title);
        data.append('slideshow_file_' + index, elem.file);
    });
    /* end */

    Axios({
        url : urlPhp + 'page/site.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('site', response.data.site);
        SetListPag('currentPageId', response.data.idPage);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}
// Register Crop
export function Reg_Crop(userId, idPage, idBtn, type, file, typeCrop, cropX, cropY, width, height, clientWidth, naturalWidth, CallbackSuccess, CallbackError){
    const data = new FormData();    
    data.append('type_page', 'site');
    data.append('edit_data', 'crop_photo');
    data.append('id_user', userId);
    data.append('idPage', idPage);
    data.append('idBtn', idBtn);
    data.append('type', type);
    data.append('file', file);   

    data.append('typeCrop', typeCrop);
    data.append('cropX', cropX);
    data.append('cropY', cropY);
    data.append('width', width);
    data.append('height', height);
    data.append('clientWidth', clientWidth);
    data.append('naturalWidth', naturalWidth);

    console.log(typeCrop, cropX, cropY, width, height, clientWidth, naturalWidth);    

    Axios({
        url : urlPhp + 'page/site.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('site', response.data.site);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Faq
export function Reg_Faq(userId, id, title, text, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'faq');
    data.append('edit_data', 'edit_or_register');
    data.append('id_user', userId);
    data.append('id', id);
    data.append('title', title);
    data.append('text', text);

    Axios({
        url : urlPhp + 'page/faq.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('faq', response.data.faq);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// AccessDash
export function Reg_AccessDash(userId, id, name, email, file, password, access, page, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();

    data.append('type_page', 'access_dash');
    data.append('edit_data', 'edit_or_register');
    data.append('id_user', userId);
    data.append('id', id);
    data.append('name', name);
    data.append('email', email);
    data.append('file', file);
    data.append('password', password);
    data.append('access', access['value']);

    page.forEach((elem, index) => {
        data.append('page_id[]', elem.id);
        data.append('page_index[]', index);
        data.append('page_order[]', elem.order_);
        data.append('page_type[]', elem.type['value']);
        data.append('page_name[]', elem.name);
        data.append('page_page[]', elem.page);

        elem.submenu.forEach((elem_1, index_1) => {
            data.append('page_submenu_id[]', elem_1.id);
            data.append('page_submenu_index[]', index);
            data.append('page_submenu_order[]', elem_1.order_);
            data.append('page_submenu_type[]', elem_1.type['value']);
            data.append('page_submenu_name[]', elem_1.name);
            data.append('page_submenu_page[]', elem_1.page);
        });
    });

    Axios({
        url : urlPhp + 'page/access_dash.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('access_dash', response.data.access_dash);
        SetListPag('currentPageId', response.data.idPage);

        if(userId == id){
            // current date
            const day = new Date();

            // add 3 day
            const add3Days = new Date();
            add3Days.setDate(day.getDate() + 3);
    
            cookies.set(typeCookieEmail, response.data.email, { path: '/', expires: add3Days }, cookiesRegister);

            if(response.data.pass !=''){
                cookies.set(typeCookiePassw, response.data.pass, { path: '/', expires: add3Days }, cookiesRegister);
            }            

            SetUserData('file', response.data.file);
            SetUserData('name', response.data.name);
            SetUserData('email', response.data.email);
        }

        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Students
export function Reg_Students(userId, id, name, email, file, password, note, complement, phone, cep, street, state, city, neighborhood, number, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'students');
    data.append('edit_data', 'edit_or_register');
    data.append('id_user', userId);
    data.append('id', id);
    data.append('name', name);
    data.append('email', email);
    data.append('file', file);
    data.append('password', password);
    data.append('note', note);
    data.append('complement', complement);
    data.append('phone', phone);
    data.append('cep', cep);
    data.append('street', street);
    data.append('state', state);
    data.append('city', city);
    data.append('neighborhood', neighborhood);
    data.append('number', number);

    Axios({
        url : urlPhp + 'page/students.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('students', response.data.students);
        SetListPag('currentPageId', response.data.idPage);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Settings
export function Reg_Settings(userId, companyName, email, text, whatsApp, logo, facebook, instagram, youtube, linkedin, colorTitle, colorBtn, colorContact, listNameMenu, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'settings');
    data.append('edit_data', 'edit_or_register');
    data.append('id_user', userId);
    
    data.append('company_name', companyName);
    data.append('email', email);
    data.append('text', text);
    data.append('whatsapp', whatsApp);
    data.append('logo', logo);
    
    data.append('facebook', facebook);
    data.append('instagram', instagram);
    data.append('youtube', youtube);
    data.append('linkedin', linkedin);
    
    data.append('color_title', colorTitle);
    data.append('color_btn', colorBtn);
    data.append('color_contact', colorContact);

    listNameMenu.forEach((elem, index) => {
        data.append('name_menu_id[]', elem.id);
        data.append('name_menu_name[]', elem.name);
    });

    Axios({
        url : urlPhp + 'page/settings.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('settings', response.data.settings);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Financial - Entry
export function Reg_Entry(userId, id, title, status, dueDate, paymentDate, price, type, idGiven, name, paymentType, newPaymentType, voucher, text, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'entry');
    data.append('edit_data', 'edit_or_register');
    data.append('id_user', userId);
    
    data.append('id', id);
    data.append('title', title);
    data.append('status', status['value']);
    data.append('due_date', dueDate);    
    data.append('payment_date', paymentDate);
    data.append('price', price);
    data.append('type', type['value']);
    data.append('id_given', idGiven['value']);
    data.append('name', name);
    data.append('payment_type', paymentType['value']);
    data.append('new_data', newPaymentType);
    data.append('voucher', voucher);
    data.append('text', text);

    Axios({
        url : urlPhp + 'page/entry.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('entry', response.data.entry);
        SetListPag('currentPageId', response.data.idPage);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}
// Financial - Exit
export function Reg_Exit(userId, id, title, status, dueDate, paymentDate, price, type, idGiven, name, paymentType, newPaymentType, voucher, text, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'exit');
    data.append('edit_data', 'edit_or_register');
    data.append('id_user', userId);
    
    data.append('id', id);
    data.append('title', title);
    data.append('status', status['value']);
    data.append('due_date', dueDate);    
    data.append('payment_date', paymentDate);
    data.append('price', price);
    data.append('type', type['value']);
    data.append('id_given', idGiven['value']);
    data.append('name', name);
    data.append('payment_type', paymentType['value']);
    data.append('new_data', newPaymentType);
    data.append('voucher', voucher);
    data.append('text', text);

    Axios({
        url : urlPhp + 'page/exit.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('exit', response.data.exit);
        SetListPag('currentPageId', response.data.idPage);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Course
export function Reg_Courses(userId, id, title, price, discount, cover, typeVideo, video, text, cropX, cropY, width, height, clientWidth, naturalWidth, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'courses');
    data.append('edit_data', 'edit_or_register');
    data.append('id_user', userId);
    
    data.append('id', id);
    data.append('title', title);
    data.append('price', price);
    data.append('discount', discount);
    data.append('type_video', typeVideo);
    data.append('video', video);
    data.append('text', text);
    
    data.append('cover', cover);
    data.append('cropX', cropX);
    data.append('cropY', cropY);
    data.append('width', width);
    data.append('height', height);
    data.append('clientWidth', clientWidth);
    data.append('naturalWidth', naturalWidth);

    Axios({
        url : urlPhp + 'page/courses.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('courses', response.data.courses);
        SetListPag('currentPageId', response.data.idPage);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}
// Classe
export function Reg_Classe(userId, idCourse, idClasse, title, releaseCourse, releaseCourseForDay, duration, durationQtdDay, idModule, newModule, typeVideo, video, text, download, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'courses');
    data.append('edit_data', 'edit_or_register_classe');
    data.append('id_user', userId);
    console.log(userId);
    
    data.append('idCourse', idCourse);
    data.append('idClasse', idClasse);
    data.append('title', title);
    data.append('release_course', releaseCourse);
    data.append('release_course_for_day', releaseCourseForDay);
    data.append('duration', duration);
    data.append('duration_qtd_day', durationQtdDay);
    data.append('id_module', idModule);
    data.append('new_module', newModule);
    data.append('type_video', typeVideo);
    data.append('video', video);
    data.append('text', text);
    
    download.forEach((elem, index) => {
        data.append('download_id[]', elem.id);
        data.append('download_title[]', elem.title);
        data.append('download_file_' + index, elem.file);
    });

    Axios({
        url : urlPhp + 'page/courses.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('courses', response.data.courses);
        SetListDataSingle('category', response.data.category);
        SetListPag('currentPageIdModule', response.data.idModule);
        SetListPag('currentPageIdClasse', response.data.idPage);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}