import { useState, useEffect } from "react";

import './Details.css';

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Select from "components/Select";
import Comp_InputFile from "components/InputFile";

import { GetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Reg_AccessDash } from "services/Register";

export default function Page_AccessDash_Details(props){

    const [ opt, setOpt ] = useState([        
        { "id": 0, "order_": 0, "type": { "value": "", "label": "" }, "name": "Site", "page": "site", "submenu": [            
            { "id": 0, "order_": 0, "type": { "value": "hide_page", "label": "Sem acesso" }, "name": "Home", "page": "home" },
            { "id": 0, "order_": 1, "type": { "value": "hide_page", "label": "Sem acesso" }, "name": "Quem somos", "page": "about" },
            { "id": 0, "order_": 2, "type": { "value": "hide_page", "label": "Sem acesso" }, "name": "Faq", "page": "faq" },
            // { "id": 0, "order_": 3, "type": { "value": "hide_page", "label": "Sem acesso" }, "name": "Nome do menu", "page": "name_menu" }
        ]},
        
        { "id": 0, "order_": 1, "type": { "value": "", "label": "" }, "name": "Financeiro", "page": "financial", "submenu": [            
            { "id": 0, "order_": 0, "type": { "value": "hide_page", "label": "Sem acesso" }, "name": "Entrada", "page": "entry" },
            { "id": 0, "order_": 1, "type": { "value": "hide_page", "label": "Sem acesso" }, "name": "Saída", "page": "exit" },
            // { "id": 0, "order_": 2, "type": { "value": "hide_page", "label": "Sem acesso" }, "name": "Balanço", "page": "balance" }
        ]},

        { "id": 0, "order_": 2, "type": { "value": "hide_page", "label": "Sem acesso" }, "name": "Configurações gerais", "page": "config", "submenu": [] }
    ]);

    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('access_dash'));

    const [ name, setName ] = useState(InitialData('name'));
    const [ email, setEmail ] = useState(InitialData('email'));
    const [ file, setFile ] = useState(InitialData('file'));
    const [ fileStatus, setFileStatus ] = useState(false);
    const [ pass, setPass ] = useState('');
    const [ access, setAccess ] = useState(InitialData('access'));
    const [ page, setPage ] = useState(InitialData('page'));
    
    let countIndex = 0;

    function InitialData(type){
        const newData = GetDataPage('access_dash').find(item => item.id == idPage);   
        if(newData){
            if(type === 'page'){
                if(newData['page'].length == 0){
                    return opt;
                }
                return newData['page'];
            }else {
                return newData[type];
            }
        }
        if(type === 'access'){
            return { "value": 0, "label": "Usuário comum" };
        }
        if(type === 'page'){
            return opt;
        }
        return '';
    }

    function HandleData(type, index, value){
        const newData = [...page];
        let sepInf = type.split('/');
        if(sepInf[1]){
            newData[index]['submenu'][sepInf[1]]['type'] = value;
        }else {
            newData[index]['type'] = value;
        }
        setPage(newData);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_AccessDash(props.idUser, idPage, name, email, file, pass, access, page, props.CallbackSuccess, props.CallbackError);
    }

    useEffect(()=>{
        RegisterDataPage('access_dash', setDataPage);
        return ()=>{
            UnRegisterDataPage('access_dash', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));
        
        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setFile(InitialData('file'));
        setFileStatus(false);
        setPass('');
        setAccess(InitialData('access'));
        setPage(InitialData('page'));
    }, [dataPage, idPage]);

    return(
        <form className="page_content page_access_dash" onSubmit={ SaveData }>
            <Comp_Topic type={ true } icon="save" SearchInput="" search="" title="Detalhes de acesso" OpenPage={ props.OpenPage } page="access_dash" idPage={ 0 } />
            
            <div className="show_page_data">
                <div className="type_title">Dados de acesso</div>
                <div className="list_input_data">
                    <Comp_Input className="" type="text" index="not" setValue={ setName } value={ name } input="" maxLength={ 140 } placeholder="" required={ true } name="Nome" />
                    
                    <Comp_Input className="" type="email" index="not" setValue={ setEmail } value={ email } input="" maxLength={ 40 } placeholder="" required={ true } name="E-mail" />
                    
                    <Comp_Input className="password" type="password" index="not" setValue={ setPass } value={ pass } input="" maxLength={ 10 } placeholder="*****" required={ idPage === 0 ? true : false } name="Senha" />

                    <Comp_InputFile index="not" setValue={ setFile } value={ file } setStatus={ setFileStatus } status={ fileStatus } OpenFile={ props.OpenFile } thumbnail={ file } title1="Adicionar foto" title2="Foto adicionado" />
                    {
                        idPage != props.idUser ?
                            <Comp_Select index="not" name="Tipo de acesso" className="access" opt={ [{ "value": 0, "label": "Usuário comum" }, { "value": 1, "label": "Administrador" }] } setValue={ setAccess } value={ access } input="" />
                        : null
                    }                    
                </div>
            </div>

            {/* {
                access['value'] == 0 ?
                    <div className="show_page_data">
                        <div className="type_title">Acesso as páginas</div>
                        <div className="list_input_data">
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <th width="20" align="center">#</th>
                                        <th>Página</th>
                                        <th width="110">Acesso</th>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        page.map((elem, index)=>{
                                            if(elem.page === "site" || elem.page === "financial"){
                                                return(
                                                    elem.submenu.map((elem_1, index_1)=>{
                                                        countIndex = countIndex + 1;
                                                        return(
                                                            <tr key={ index_1 }>
                                                                <td align="center">{ countIndex }</td>
                                                                <td>{ elem.name } - { elem_1.name }</td>
                                                                <td>                                                        
                                                                    <Comp_Select index={ index } name="" className="access_page" opt={ [{ "value": "hide_page", "label": "Sem acesso" }, { "value": "show_page", "label": "Acesso liberado" }] } setValue={ HandleData } value={ elem_1.type } input={ "type/" + index_1 } />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                )

                                            }else {
                                                countIndex = countIndex + 1;
                                                return(
                                                    <tr key={ index }>
                                                        <td align="center">{ countIndex }</td>
                                                        <td>{ elem.name }</td>
                                                        <td>                                                        
                                                            <Comp_Select index={ index } name="" className="access_page" opt={ [{ "value": "hide_page", "label": "Sem acesso" }, { "value": "show_page", "label": "Acesso liberado" }] } setValue={ HandleData } value={ elem.type } input="type" />
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                : null
            } */}
        </form>
    )
}