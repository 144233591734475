import Cookies from 'universal-cookie';

import { cookiesRegister, typeCookiePage, typeCookiePageId, typeCookiePageIdClasse, typeCookiePageIdModule } from 'fixedData';

const cookies = new Cookies();

let ListPag = {
    "page" : 'login',
    "currentPage" : 'index',
    "currentPageId" : 0,
    "currentPageIdModule" : 0,
    "currentPageIdClasse" : 0
};

let NotifyListPag = {
    "page" : [],
    "currentPage" : [],
    "currentPageId" : [],
    "currentPageIdModule" : [],
    "currentPageIdClasse" : []
}

export function SetListPag(key, value){
    ListPag[key] = value;
    NotifyListPag[key].forEach(element => {
        element(value);
    });

    // current date
    const day = new Date();

    // add 3 day
    const add3Days = new Date();
    add3Days.setDate(day.getDate() + 3);

    if(key == 'currentPageId'){
        if(value == 'remuve'){
            cookies.remove(typeCookiePageId, '', { path: '/', expires: add3Days }, cookiesRegister);
        }else {
            cookies.set(typeCookiePageId, value, { path: '/', expires: add3Days }, cookiesRegister);
        }
    }

    if(key == 'currentPageIdModule'){
        if(value == 'remuve'){
            cookies.remove(typeCookiePageIdModule, '', { path: '/', expires: add3Days }, cookiesRegister);
        }else {
            cookies.set(typeCookiePageIdModule, value, { path: '/', expires: add3Days }, cookiesRegister);
        }
    }

    if(key == 'currentPageIdClasse'){
        if(value == 'remuve'){
            cookies.remove(typeCookiePageIdClasse, '', { path: '/', expires: add3Days }, cookiesRegister);
        }else {
            cookies.set(typeCookiePageIdClasse, value, { path: '/', expires: add3Days }, cookiesRegister);
        }
    }

    if(key == 'currentPage'){
        cookies.set(typeCookiePage, value, { path: '/', expires: add3Days }, cookiesRegister);
    }
}

export function GetListPag(key){
    return ListPag[key];
}

export function RegisterListPag(key, value){
    if(!NotifyListPag[key]){
        NotifyListPag[key] = [];
    }
    NotifyListPag[key].push(value);
}

export function UnRegisterListPag(key, callback){
    if(NotifyListPag[key].length > 0){
        NotifyListPag[key] = NotifyListPag[key].filter((item) => {
            return item !== callback;
        });
    }
}